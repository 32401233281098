;(function() {
  'use strict'

  Service.$inject = ["Money", "subscriberService", "hostingCreditService"];
  angular.module('app.core').service('channelCostService', Service)

  /* @ngInject */
  function Service(Money, subscriberService, hostingCreditService) {
    var self = this

    self.calculate = calculate

    window.channelCostService = this

    function calculate(channel) {
      var cost = calculateHostingCredit(channel)
      if (channel.type === channel.Type.PANEL) {
        cost.panel = calculatePanel(channel)
      }
      return cost
    }

    function calculateHostingCredit(channel) {
      var subscriber = subscriberService.getSubscriber()
      var balance = subscriber.hostingCredit.balance
      var perResponse = hostingCreditService.COST_PER_COMPLETE
      var perExit = hostingCreditService.COST_PER_EXIT
      var totalResponses = channel.usesPanel()
        ? channel.panel.requiredCompletes
        : channel.responseLimit
      var complete = channel.usesPanel()
        ? !!channel.panel.incidenceRate && !!totalResponses
        : !!totalResponses
      var total = perResponse * totalResponses
      var responseCount = channel.responseCount
      var remainingTotal = perResponse * (totalResponses - responseCount)

      return {
        complete: complete,
        totalResponses: totalResponses,
        response: perResponse,
        exit: perExit,
        total: total,
        balance: balance,
        remaining: balance - remainingTotal,
        hasEnoughBalance: balance >= total,
        autoRenew: subscriber.hostingCredit.isAutoRenew(),
      }
    }

    function calculatePanel(channel) {
      var subscriber = subscriberService.getSubscriber()
      var currency = subscriber.billing.currency
      var balance = subscriber.billing.balance
      var threshold = subscriber.billing.threshold
      var cost = {}
      var base = subscriber.plan.pricing.base.byCurrency(currency)
      var response = _(subscriber.plan.pricing.responses)
        .find({
          type: channel.panel.lengthOfInterview.type.type,
        })
        .price.byCurrency(currency)

      // Previously: cost per response was BASE + PANEL
      // cost.response = base.clone()
      // cost.response.amount += response.amount
      // Current: revert back to using only PANEL cost for response
      cost.response = response.clone()

      cost.balance = balance.clone()
      cost.threshold = threshold.clone()
      cost.exit = subscriber.plan.pricing.exits.byCurrency(currency)

      // calculate total if we have the required data
      if (channel.panel.incidenceRate && channel.panel.requiredCompletes) {
        var incidencePercent = channel.panel.incidenceRate / 100
        var totalExits =
          channel.panel.requiredCompletes / incidencePercent -
          channel.panel.requiredCompletes
        var totalResponses = channel.panel.requiredCompletes
        var totalResponseCost = totalResponses * cost.response.amount
        var totalExitCost = totalExits * cost.exit.amount

        cost.totalResponses = totalResponses
        cost.total = new Money({ currency: currency })
        cost.total.amount = Math.round(totalResponseCost + totalExitCost)
        cost.complete = true
      } else {
        cost.complete = false
      }

      cost.hasEnoughBalance = cost.total && balance.amount >= cost.total.amount

      // console.log('calculatePanel', cost)
      return cost
    }
  }
})()
