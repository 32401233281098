;(function() {
  'use strict'

  userService.$inject = ["$q", "$rootScope", "$state", "$location", "AUTH_EVENTS", "glAnalytics", "glPrefs", "glDialog", "glAuthService", "usersResource", "subSelector", "orgSelector", "elementService", "resources"];
  angular.module('app.core').factory('userService', userService)

  /* @ngInject */
  function userService(
    $q,
    $rootScope,
    $state,
    $location,
    AUTH_EVENTS,
    glAnalytics,
    glPrefs,
    glDialog,
    glAuthService,
    usersResource,
    subSelector,
    orgSelector,
    elementService,
    resources
  ) {
    var prefs = glPrefs.create('userService')

    var REQUESTED_SUBSCRIBER_KEY = 'requestedSubscriber'
    var REQUESTED_ORG_KEY = 'requestedOrg'

    var currentUser // user object
    var requestedUrl // url user requested before logging in
    var requestedSubscriber // requested subscriber ID
    var requestedOrg // requested organisation(subscriber) ID
    var Events = {
      CHANGE: 'userService:CHANGE',
    }

    return {
      getUser: getUser,
      getUserProfile: getUserProfile,
      create: create,
      createEmailUser: createEmailUser,
      loginDialog: loginDialog,
      login: login,
      logout: logout,
      hasCredentials: hasCredentials,
      isLoggedIn: isLoggedIn,
      setRequestedSubscriber: setRequestedSubscriber,
      getRequestedSubscriber: getRequestedSubscriber,
      setRequestedOrg: setRequestedOrg,
      getRequestedOrg: getRequestedOrg,
      saveRequestedUrl: saveRequestedUrl,
      redirectToRequestedUrl: redirectToRequestedUrl,
      setInitialRoute: setInitialRoute,
      saveUserProfile: saveUserProfile,
      resetPassword: resetPassword,
      updatePasswordReset: updatePasswordReset,
      getSubscriberId: getSubscriberId,
      isSSR: isSSR,
      hasRoles: hasRoles,
      Events: Events,
    }

    /**
     * @name getUser
     * @description returns current user object
     * @return {Object} current user
     */
    function getUser() {
      return currentUser
    }

    /**
     * @name getUserProfile
     * @description Get a users profile
     * @return {Promise}
     */
    function getUserProfile() {
      return $q(function(resolve, reject) {
        usersResource
          .get({ id: currentUser.id })
          .success(function(x, data) {
            resolve(data)
          })
          .error(reject)
      })
    }

    /**
     * @name create
     * @desc create a user
     * @param {Object} data - The user data
     * @returns {Promise}
     */
    function create(data) {
      return $q(function(resolve, reject) {
        usersResource
          .signup(data)
          .success(function(x, data) {
            resolve(data)
          })
          .error(reject)
      })
    }

    function createEmailUser(data, bypassEmailValidation) {
      return $q(function(resolve, reject) {
        if (bypassEmailValidation) {
          // obfuscated attribute name for bypassing email validation
          data.legacy = true
        }
        usersResource
          .createEmailUser(data)
          .success(function(x, response) {
            resolve(response)
          })
          .error(function(error) {
            error.status = error.__status
            reject(error)
          })
      })
    }

    function loginDialog(options, title) {
      options = options || {}
      var template = [
        '<gl-dialog class="login-dialog__dialog">',
        '<login-dialog',
        'title="title"',
        'options="options"',
        'on-login="dialog.close($user)"',
        'on-close="dialog.cancel()"',
        'on-request-password-reset="dialog.cancel(\'passwordReset\')"',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(' '),
        locals: {
          title: title,
          options: options,
        },
      })
    }

    /**
     * @name  login
     * @description log user in
     * @param  {Object} auth auth object that contains email and password
     * @return {Promise}
     */
    function login(auth, allowedRoles) {
      return glAuthService.login(auth, allowedRoles).then(function(userData) {
        var isSupport = _.includes(
          userData.roles,
          glAuthService.Roles.SUBSCRIBER_SUPPORT
        )
        if (isSupport) {
          return subSelector
            .select()
            .then(function(subscriberId) {
              setRequestedSubscriber(subscriberId, true)
              return setUser(userData)
            })
            .catch(function() {
              glAuthService.logout()
              return $q.reject()
            })
        } else {
          return orgSelector
            .select(userData)
            .then(function(orgId) {
              setRequestedOrg(orgId)
              return setUser(userData)
            })
            .catch(function() {
              glAuthService.logout()
              return $q.reject()
            })
        }
      })
    }

    /**
     * @name  logout
     * @description log user out and clean up any persisted data
     * @return {Promise}
     */
    function logout(noRedirect) {
      return glAuthService.logout().then(function() {
        setRequestedSubscriber(null, true)
        setRequestedOrg(null)
        setUser(null)
        elementService.clearElementCache() // TODO: cant elementservice listen for logout event?
        $rootScope.$emit(AUTH_EVENTS.logoutSuccess) // TODO: shouldn't auth service do this?
        if (noRedirect) {
          return
        }
        // users can remain on some public routes after logging out.
        var currentRoute = $state.current.name
        var publicRoutes = ['store', 'publication', 'publisherStore']
        if (_.includes(publicRoutes, currentRoute)) {
          $state.reload()
        } else {
          $state.go('home')
        }
      })
    }

    function setUser(user) {
      if (user && user.jobRole) {
        glAnalytics.set(glAnalytics.Dimensions.JOB_ROLE, user.jobRole.code)
      }
      currentUser = user
      $rootScope.$emit(Events.CHANGE, currentUser)
      return currentUser
    }

    /**
     * @name hasCredentials
     * @description check if the user can log in
     * @return {Boolean} if the user is can log in
     */
    function hasCredentials() {
      return glAuthService.hasCredentials()
    }

    /**
     * @name isLoggedIn
     * @description check if the user is logged in
     * @return {Boolean} if the user is logged in
     */
    function isLoggedIn() {
      return !!currentUser
    }

    /**
     * @name setRequestedSubscriber
     * @description set the requested subscriber ID for use with SSR users
     * @param {String} subscriberId The subscriber ID
     * @param {Boolean} stored Whether the remember the requested subscriberId
     */
    function setRequestedSubscriber(subscriberId, stored) {
      requestedSubscriber = subscriberId
      if (stored) {
        prefs.set(REQUESTED_SUBSCRIBER_KEY, subscriberId)
      }
      // set resources subscriber ID to be added in request headers
      resources.setSubscriberId(subscriberId)
    }

    /**
     * @name getRequestedSubscriber
     * @description get the requested subscriber ID  null if not set
     * @returns {String} requestedSubscriberId
     */
    function getRequestedSubscriber() {
      return requestedSubscriber || prefs.get(REQUESTED_SUBSCRIBER_KEY)
    }

    /**
     * @name setRequestedOrg
     * @description set the requested organisation ID for use with non-SSR users
     * @param {String} orgId The organisation ID
     */
    function setRequestedOrg(orgId) {
      requestedOrg = orgId
      prefs.set(REQUESTED_ORG_KEY, orgId)
      // set resources subscriber ID to be added in request headers
      resources.setSubscriberId(orgId)
    }

    /**
     * @name getRequestedOrg
     * @description get the requested organisation ID  null if not set
     * @returns {String} requestedOrgId
     */
    function getRequestedOrg() {
      return requestedOrg || prefs.get(REQUESTED_ORG_KEY)
    }

    /* getSubscriberId
     * @desc returns the group id of the user or the requested subscriber id for ssr users
     * @returns {String} subscriberId
     */
    function getSubscriberId() {
      if (!currentUser) {
        return
      }
      if (isSSR()) {
        return getRequestedSubscriber()
      }
      return getRequestedOrg()
    }

    /**
     * isSSR
     * @desc determine if the user is SSR based on roles
     * @returns {Boolean} isSSR
     */
    function isSSR() {
      if (!currentUser) {
        return false
      }
      return _.includes(
        currentUser.roles,
        glAuthService.Roles.SUBSCRIBER_SUPPORT
      )
    }

    /**
     * @name saveRequesterUrl
     * @description Save requested url if user is not logged in, to be routed to after succesful login
     */
    function saveRequestedUrl() {
      if ($location.path().toLowerCase() !== '/') {
        requestedUrl = $location.url()
      }
    }

    /**
     * @name redirectToRequestedUrl
     * @description Redirect to requested url if it exists -- called on login success from login controller
     */
    function redirectToRequestedUrl() {
      if (requestedUrl) {
        $location.url(requestedUrl)
        requestedUrl = ''
      } else if ($state.current.name === 'login') {
        $state.go('dashboard', null, { location: 'replace' })
      } else {
        $state.go('dashboard', null, { reload: true })
      }
    }

    /**
     * @name saveUserProfile
     * @description save users profile to glow service
     * @param {Object} user user object
     * @return {Promise} promise
     */
    function saveUserProfile(user) {
      return $q(function(resolve, reject) {
        usersResource
          .update(user)
          .success(function() {
            setUser(user)
            resolve()
          })
          .error(reject)
      })
    }

    function setInitialRoute(initialRoute) {
      currentUser.initialRoute = initialRoute
      // HACK: this action is named `updateNextLogin` and it takes a `nextLogin`
      // field but in reality we are modifying the `initialRoute` so we use that
      // terminology everywhere except here.
      usersResource
        .updateNextLogin({
          id: currentUser.id,
          nextLogin: initialRoute,
        })
        .success(angular.noop)
    }

    /**
     * @name resetPassword
     * @description create password reset request
     * @return {Promise} promise
     */
    function resetPassword(email, newPassword) {
      return $q(function(resolve, reject) {
        var data = {
          email: email,
          newPassword: newPassword,
          requesterDomain: window.location.hostname,
        }
        usersResource
          .passwordReset(data)
          .success(resolve)
          .error(reject)
      })
    }

    /**
     * @name updatePasswordReset
     * @description redeem password reset validation token
     * @param {String} token reset token to be sent for verification
     * @return {Promise} promise
     */
    function updatePasswordReset(token) {
      return $q(function(resolve, reject) {
        usersResource
          .updatePasswordReset({ id: token })
          .success(resolve)
          .error(reject)
      })
    }

    // Returns true if user has at least one of the roles
    function hasRoles(email, roles) {
      return $q(function(resolve) {
        usersResource
          .getRolesByEmail({ email: email })
          .success(function(x, resp) {
            var hasRoles = false
            _.each(roles, function(role) {
              hasRoles = hasRoles || _.includes(resp.roles, role)
            })
            resolve(hasRoles)
          })
          .error(function() {
            resolve(false)
          })
      })
    }
  }
})()
