;(function() {
  'use strict'

  Service.$inject = ["$rootScope", "$q", "$http", "Datapack", "Dashboard", "configService", "surveyService", "glAuthService"];
  angular.module('app.core').service('datapackService', Service)

  /* @ngInject */
  function Service(
    $rootScope,
    $q,
    $http,
    Datapack,
    Dashboard,
    configService,
    surveyService,
    glAuthService
  ) {
    this.createDatapack = createDatapack
    this.getDatapack = getDatapack
    this.getCSV = getCSV
    this.listDashboards = listDashboards
    this.getDashboard = getDashboard
    this.saveDashboard = saveDashboard
    this.deleteDashboard = deleteDashboard

    function createDatapack(surveyId) {
      var options = {
        method: 'POST',
        url: configService.getDatapackUrl(`/tasks`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          handler: 'App\\Task\\Handlers\\GenerateDataPackForSurvey',
          payload: {
            surveyId: surveyId,
          },
        }),
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $q(function(resolve, reject) {
        $http(options)
          .then(function(resp) {
            var taskId = resp.data.id
            function checkStatus() {
              var options = {
                method: 'GET',
                url: configService.getDatapackUrl(`/tasks/${taskId}`),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
              if (glAuthService.hasCredentials()) {
                options.headers.Authorization = 'Bearer ' + glAuthService.getToken() // prettier-ignore
              }

              $http(options)
                .then(function(resp) {
                  if (resp.data.status === 'SUCCESS') {
                    resolve(resp.data)
                  } else if (resp.data.status === 'ERROR') {
                    reject(resp.data)
                  } else {
                    setTimeout(checkStatus, 2000)
                  }
                })
                .catch(function(error) {
                  reject(error)
                })
            }
            checkStatus()
          })
          .catch(function(error) {
            reject(error)
          })
      })
    }

    function getDatapack(survey) {
      var options = {
        method: 'GET',
        url: configService.getDatapackUrl(`/datapacks/${survey.id}`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $http(options).then(function(resp) {
        var datapack = new Datapack(survey).deserialize(resp.data)
        return datapack
      })
    }

    function getCSV(surveyId) {
      var url = configService.getDatapackUrl(`/datapacks/${surveyId}.glw`)
      var options = {
        method: 'GET',
      }
      return fetch(url, options)
        .then(function(resp) {
          return resp.arrayBuffer()
        })
        .then(function(buf) {
          // console.log('buf', buf)
          var str = pako.inflate(buf, { to: 'string' })
          // console.log('str', str)
          var csv = Papa.parse(str)
          // console.log('csv', csv)
          return csv.data
        })
    }

    function listDashboards(surveyId) {
      var options = {
        method: 'GET',
        url: configService.getDatapackUrl(
          `/dashboards?surveyId=${surveyId}&redacted=true`
        ),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      return $http(options).then(function(resp) {
        console.log('listDashboards resp', resp)
        return resp.data
      })
    }

    function getDashboard(survey, id) {
      var options = {
        method: 'GET',
        url: configService.getDatapackUrl(`/dashboards/${id}`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      return $http(options).then(function(resp) {
        if (survey) {
          return new Dashboard(survey)
            .deserialize(resp.data)
            .migrate()
            .commit()
        }
        return surveyService
          .getAsSurvey(resp.data.surveyId)
          .then(function(survey) {
            return new Dashboard(survey)
              .deserialize(resp.data)
              .migrate()
              .commit()
          })
      })
    }

    function saveDashboard(dashboard) {
      dashboard.saving = true
      var options = {
        method: dashboard.isNew ? 'POST' : 'PUT',
        url: dashboard.isNew
          ? configService.getDatapackUrl(`/dashboards`)
          : configService.getDatapackUrl(`/dashboards/${dashboard.id}`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(dashboard.serialize(true)),
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $http(options).then(function(resp) {
        if (dashboard.isNew) {
          dashboard.id = resp.data.id
          dashboard.isNew = false
        }
        dashboard.saving = false
        dashboard.commit()
        dashboard.snapshotTheme()
      })
    }

    function deleteDashboard(dashboard) {
      var options = {
        method: 'DELETE',
        url: configService.getDatapackUrl(`/dashboards/${dashboard.id}`),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      if (glAuthService.hasCredentials()) {
        options.headers.Authorization = 'Bearer ' + glAuthService.getToken()
      }
      return $http(options)
    }
  }
})()
