;(function() {
  'use strict'

  angular.module('app.core').component('surveyExplorerThemeDialog', {
    controller: Controller,
    templateUrl: 'survey-explorer-theme-dialog.html',
    bindings: {
      subscriber: '<',
      dashboard: '<',
      view: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.done = done
    ctrl.cancel = cancel

    function onInit() {
      ctrl.global = false
      console.log('theme:sub', ctrl.subscriber.themeContext)
      console.log('theme:dp', ctrl.dashboard.theme)
      console.log('theme:view', ctrl.view.theme)
    }

    function done() {
      ctrl.onDone()
    }

    function cancel() {
      ctrl.onCancel()
    }
  }
})()
