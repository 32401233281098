;(function() {
  'use strict'

  angular.module('app.core').component('featureList', {
    controller: Controller,
    templateUrl: 'feature-list.html',
    bindings: {},
  })

  /* @ngInject */
  function Controller() {
    var ctrl = this

    ctrl.allFeatures = [
      {
        title: 'Invite Users',
        desc: 'Add new users to your account to collaborate',
      },
      {
        title: 'Unlimited Projects',
        desc:
          'Create unlimited research projects on your account, and invite other users to collaborate in each project as required',
      },
      {
        title: 'Unlimited Surveys',
        desc:
          'Create unlimited surveys on your Glow account. No feature limits on creating simple or complex surveys - so much to explore!',
      },
      {
        title: 'Save Reports',
        desc:
          'Save public and private reports that are shared by other users on your Glow account',
      },
      {
        title: 'Online Analysis',
        desc:
          'Analyse your data instantly as responses arrive on your account, with tools that make it easy to accelerate your decision making',
      },
      {
        title: 'Interactive Data Sharing',
        desc:
          'Share rich interactive survey data with other people outside your account.',
      },
      {
        title: 'Export to CSV',
        desc:
          'Export the individual responses in text format (csv) or in numeric format (xlsx) for use in third-party tools',
      },
      {
        title: 'Integrated Consumer Panel',
        desc:
          "Use Glow's integrated panel to quickly connect to a worldwide audience of over 110 million people from quality, reliable sources. ",
      },
      {
        title: 'Clone surveys',
        desc:
          'No need to start from scratch when you can duplicate your surveys, import questions from other surveys, or drop in preset questions from your question library   ',
      },
      {
        title: 'Custom Analysis Branding',
        desc:
          'Modify the colours in your charts to fit your survey theme or align with your corporate branding. ',
      },
      {
        title: 'Automatic survey translation',
        desc:
          'Machine translate your survey into other languages or share the translation link with human translators for easy modification to support global distribution ',
      },
      {
        title: 'Project Files & Links',
        desc:
          'Upload key documents and links that you and your team need to manage your projects in Glow',
      },
      {
        title: 'Quota and Tag management',
        desc:
          'Control data tagging and the number of responses you capture in surveys',
      },
      {
        title: 'Quick Panel',
        desc:
          'Need data fast? Accelerate your project so you can get into the analysis quickly. Choose how long your survey is in field when using the integrated consumer panel',
      },
      {
        title: 'Panel Targeting',
        desc:
          'When using our integrated panel, default to nationally representative quotas for targeting respondents or pick your own target respondents quickly and easily',
      },
      {
        title: 'Survey channel redirects',
        desc:
          'Have a preferred panel partner? Work with any panel you like using third party panel redirects. ',
      },
      {
        title: 'Publish Reports',
        desc:
          'Share richer deliverables by combining resources into a packaged report you can store on your account. Share report links with your customers so they can store them on their accounts, too!',
      },
    ]

    ctrl.$onInit = onInit

    function onInit() {
      //...
    }
  }
})()
