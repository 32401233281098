;(function() {
  'use strict'

  Controller.$inject = ["$state", "glUtils", "glDialog", "glToast", "publicationService", "publisherService", "resourceService", "subscriberService", "clipboardService", "industryService", "userService", "countryService", "helpService", "uacService", "projectService"];
  angular
    .module('app.core')
    .controller('PublicationEditorController', Controller)

  /* @ngInject */
  function Controller(
    $state,
    glUtils,
    glDialog,
    glToast,
    publicationService,
    publisherService,
    resourceService,
    subscriberService,
    clipboardService,
    industryService,
    userService,
    countryService,
    helpService,
    uacService,
    projectService
  ) {
    var ctrl = this
    ctrl.ResourceItemClassPrefix = 'resource-'

    ctrl.save = save
    ctrl.updateState = updateState
    ctrl.requestToPublish = requestToPublish
    ctrl.updateInStore = updateInStore
    ctrl.scrollTo = scrollTo
    ctrl.addResource = addResource
    ctrl.editResource = editResource
    ctrl.removeResource = removeResource
    ctrl.moveResource = moveResource
    ctrl.copyLink = copyLink
    ctrl.setIndustry = setIndustry
    ctrl.setCountry = setCountry
    ctrl.setProject = setProject
    ctrl.canPublish = canPublish
    ctrl.isSaving = isSaving

    init()

    function init() {
      ctrl.isForbidden = !uacService.canManageReports()
      ctrl.isSSR = userService.isSSR()
      ctrl.subscriber = subscriberService.getSubscriber()
      ctrl.States = publicationService.States
      ctrl.Types = publicationService.Types
      ctrl.TypeInfo = publicationService.TypeInfo
      ctrl.Industries = _.map(industryService.getAll(), function(industry) {
        return {
          value: industry.code,
          label: industry.name,
        }
      })
      ctrl.Countries = _.map(countryService.getAll(), function(country) {
        return {
          value: country.cc,
          label: country.name,
        }
      })
      projectService.load().then(function(projects) {
        function orderByCreation(projects) {
          return _.orderBy(
            projects,
            function(project) {
              return project.createdAt.unix()
            },
            'desc'
          )
        }
        projects = orderByCreation(projects)
        var archived = orderByCreation(projectService.getArchived())
        var allProjects = _.concat(projects, archived)
        ctrl.projectOptions = _.map(allProjects, function(project) {
          return {
            value: project.id,
            label: project.name,
          }
        })
      })
      if (!ctrl.isForbidden) {
        initPublication()
      }
    }

    function initPublication() {
      var id = $state.params.id
      if (id === 'new') {
        ctrl.publication = publicationService.getBlank()
        ctrl.publication.ownerId = ctrl.subscriber.id
        // default the acquisition type to PREMIUM
        ctrl.publication.type = _.find(ctrl.TypeInfo, {
          type: ctrl.Types.PREMIUM,
        })
        if ($state.params.projectId) {
          setProject($state.params.projectId)
        }
      } else {
        publicationService.getById(id, function(err, publication) {
          if (err) {
            ctrl.err = err
            return
          }
          publicationService
            .populateResources(publication)
            .then(function() {
              ctrl.publication = publication
            })
            .catch(function(err) {
              ctrl.err = err
            })
        })
      }
    }

    function save() {
      if (isSaving()) return
      if (!ctrl.publication.validate()) {
        return
      }
      if (!publisherService.publishers.length) {
        // if there's no publisher, create one using organisation data
        var publisher = publisherService.getBlank()
        publisher.name = ctrl.subscriber.name
        if (ctrl.subscriber.portalTheme.logoImageUrl) {
          publisher.imageUrl = ctrl.subscriber.portalTheme.logoImageUrl
        }
        ctrl.isSavingPublisher = true
        publisherService.save(publisher).then(function() {
          ctrl.isSavingPublisher = false
          doSave()
        })
      } else {
        doSave()
      }
    }

    function doSave() {
      publicationService
        .save(ctrl.publication)
        .then(function() {
          if ($state.params.id === 'new') {
            glUtils.changeParams({ id: ctrl.publication.id })
          }
          glToast.show('Report saved')
        })
        .catch(function(error) {
          glToast.show('Error saving report: ' + error.data.message)
        })
    }

    function updateState(state) {
      publicationService.updateState(ctrl.publication, state)
    }

    function requestToPublish() {
      publicationService
        .requestToPublish(ctrl.publication)
        .then(function() {
          glToast.show('Your request to publish report has been sent.')
        })
        .catch(function(err) {
          glToast.show('Error: ' + err.message)
        })
    }

    function updateInStore(inStore) {
      // HACK: Tim wanted to set up a publisher for a client so they
      // can create and make publications available, but not let them
      // publish to the store. This code puts a blocker in front of the
      // publish button so that only SSR users can use it.
      if (inStore && !ctrl.isSSR) {
        glDialog
          .alert(
            'Contact Us',
            'Please get in touch if you would like to publish your report in the store.'
          )
          .then(function() {
            helpService.chat()
          })
      } else {
        publicationService.updateInStore(ctrl.publication, inStore)
      }
    }

    function copyLink() {
      clipboardService.copy(ctrl.publication.getLink())
    }

    function addResource() {
      resourceService.showEditor().then(function(resource) {
        ctrl.publication.resources.push(resource)
      })
    }

    function editResource(resource) {
      resourceService.showEditor(resource)
    }

    function removeResource(resource) {
      _.remove(ctrl.publication.resources, resource)
    }

    function moveResource(resource, amount, index) {
      glUtils
        .animateMove(ctrl.ResourceItemClassPrefix, index, amount)
        .then(function() {
          var resources = ctrl.publication.resources
          var fromIndex = _.indexOf(resources, resource)
          var toIndex = fromIndex + amount
          resources.splice(toIndex, 0, resources.splice(fromIndex, 1)[0])
        })
    }

    function setIndustry(value) {
      ctrl.publication.industry = industryService.getByCode(value)
    }

    function setCountry(value) {
      ctrl.publication.country = countryService.getByCC(value)
    }

    function setProject(value) {
      ctrl.publication.projectId = value
    }

    function canPublish() {
      return (
        !ctrl.publication.isNew() &&
        ctrl.publication.state !== publicationService.States.NOT_AVAILABLE
      )
    }

    function isSaving() {
      return ctrl.isSavingPublisher || ctrl.publication.isSaving
    }
  }
})()
