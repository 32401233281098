;(function() {
  'use strict'

  Service.$inject = ["$q", "$log", "$rootScope", "AUTH_EVENTS", "glDialog", "glPrefs", "usersResource"];
  angular.module('core.orgSelector').service('orgSelector', Service)

  /* @ngInject */
  function Service(
    $q,
    $log,
    $rootScope,
    AUTH_EVENTS,
    glDialog,
    glPrefs,
    usersResource
  ) {
    var self = this
    var prefs = glPrefs.create('orgSelector')
    var cache = {} /* userId: [subscribers] */

    self.select = select
    self.getOrgId = getOrgId
    self.setOrgId = setOrgId
    self.getUserSubscribers = getUserSubscribers

    init()

    function init() {
      $rootScope.$on(AUTH_EVENTS.logoutSuccess, onLogout)
    }

    function select(user, reselect) {
      if (!user) {
        $log.error('orgSelector.select require user data')
        return $q.reject()
      }

      return getUserSubscribers(user.id).then(function(subscribers) {
        if (!subscribers.length) {
          $log.error('getUserSubscribers returns empty data')
          return $q.reject()
        }

        var orgId = getOrgId()

        if (!reselect) {
          // check if stored orgId exist in subscribers
          if (orgId && !!_.find(subscribers, { id: orgId })) {
            return $q.resolve(orgId)
          }
          // if only one then elect this one
          if (subscribers.length === 1) {
            setOrgId(subscribers[0].id)
            return $q.resolve(subscribers[0].id)
          }
        }

        var options = _.map(subscribers, function(subscriber) {
          var option = {
            id: subscriber.id,
            name: subscriber.name,
          }
          if (subscriber.id === orgId) {
            option.selected = true
          }
          return option
        })
        var template = [
          '<gl-dialog class="org-selector__dialog">',
          '<org-selector options="options" on-select="dialog.close($id)" on-cancel="dialog.cancel()" />',
          '</gl-dialog>',
        ].join('')
        return glDialog
          .show({
            template: template,
            locals: {
              options: options,
            },
          })
          .then(function(orgId) {
            setOrgId(orgId)
            return orgId
          })
      })
    }

    function getOrgId() {
      return prefs.get('orgId')
    }

    function setOrgId(orgId) {
      prefs.set('orgId', orgId)
    }

    function getUserSubscribers(userId, fromCache) {
      return $q(function(resolve, reject) {
        if (fromCache && cache[userId]) {
          return resolve(cache[userId])
        }
        usersResource
          .getSubscribers({ id: userId })
          .success(function(x, data) {
            cache[userId] = data
            resolve(data)
          })
          .error(reject)
      })
    }

    function onLogout() {
      setOrgId(null)
    }
  }
})()
