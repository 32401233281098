;(function() {
  'use strict'

  Controller.$inject = ["$q", "$rootScope", "$state", "$window", "glDialog", "glTips", "glUtils", "Survey", "StateFactory", "app", "glPrefs", "surveyService", "surveyExplorerExitService", "surveyTemplateService", "surveyTemplateInstructionsService", "userService", "channelService", "translationService", "datapackService", "originService", "Channel"];
  angular.module('app.survey').controller('Survey', Controller)

  /* @ngInject */
  function Controller(
    $q,
    $rootScope,
    $state,
    $window,
    glDialog,
    glTips,
    glUtils,
    Survey,
    StateFactory,
    app,
    glPrefs,
    surveyService,
    surveyExplorerExitService,
    surveyTemplateService,
    surveyTemplateInstructionsService,
    userService,
    channelService,
    translationService,
    datapackService,
    originService,
    Channel
  ) {
    var ctrl = this
    var legacyPrefs = glPrefs.create('legacyAnalysis')

    ctrl.$onInit = onInit
    ctrl.state = new StateFactory(['loading', 'ready', 'error'])
    ctrl.setTab = setTab
    ctrl.back = back
    ctrl.canEdit = canEdit
    ctrl.canAnalyze = canAnalyze
    ctrl.onSurveyCreate = onSurveyCreate
    ctrl.onRefresh = onRefresh
    ctrl.setAppTitle = setAppTitle
    ctrl.toggleLegacy = toggleLegacy

    ctrl.tabs = [
      {
        label: 'Build',
        value: 'editor',
        enabled: canEdit,
        resolveOnLeave: promptDiscard,
      },
      {
        label: 'Capture',
        value: 'channels',
        enabled: canManageChannels,
        tip: 'audiences',
      },
      { label: 'Analyse', value: 'analysis', enabled: canAnalyze },
    ]

    $rootScope.$on(Channel.Events.CREATED, incrementActiveChannelCount)
    $rootScope.$on(Channel.Events.ENABLED, incrementActiveChannelCount)
    $rootScope.$on(Channel.Events.DISABLED, decrementActiveChannelCount)
    $rootScope.$on('toggleLegacy', toggleLegacy)

    function onInit() {
      ctrl.legacy = legacyPrefs.get('enabled')
      console.log('FOOBAR', ctrl.legacy)
      ctrl.isSSR = userService.isSSR()
      ctrl.title = $state.params.title
      ctrl.surveyId =
        $state.params.surveyId === 'new' ? null : $state.params.surveyId
      ctrl.surveyTemplateId = $state.params.surveyTemplateId
      ctrl.cloneSurveyId = $state.params.cloneSurveyId
      ctrl.projectId = $state.params.projectId
      ctrl.state.loading()
      $q.all([loadChannels(), loadSurvey(), loadTranslations()])
        .then(function() {
          if (isValidTab($state.params.tab)) {
            setTab($state.params.tab)
          } else if (ctrl.survey.responseCount > 0) {
            setTab('analysis')
          } else {
            setTab('editor')
          }
          ctrl.state.ready()
          glTips.ready()
        })
        .then(function() {
          if (ctrl.surveyTemplateId) {
            surveyTemplateInstructionsService.show({
              templateName: ctrl.originalTitle,
            })
          }
        })
        .catch(function(error) {
          console.error(error)
          ctrl.state.error()
        })
    }

    function loadChannels() {
      if (!ctrl.surveyId) return $q.resolve()
      return channelService.getBySurvey(ctrl.surveyId, function(channels) {
        ctrl.channels = channels
      })
    }

    function loadTranslations() {
      if (!ctrl.surveyId) return $q.resolve()
      ctrl.translations = translationService.listBySurvey(ctrl.surveyId)
      return ctrl.translations.promise
    }

    function copyTranslationsFromAnotherSurvey(fromSurveyId) {
      translationService
        .listBySurvey(fromSurveyId)
        .promise.then(function(translations) {
          translations = translations.map(function(translation) {
            translation = translation.clone().refresh()
            translation.surveyId = ctrl.surveyId
            return translation
          })
          var savePromises = translations.map(function(t) {
            return translationService.save(t)
          })
          return $q.all(savePromises).then(function() {
            loadTranslations()
          })
        })
    }

    function loadSurvey() {
      ctrl.survey = null
      return $q(function(resolve, reject) {
        if (!ctrl.surveyId && !ctrl.surveyTemplateId && !ctrl.cloneSurveyId) {
          var survey = new Survey()
          survey.title = ctrl.title
          survey.projectId = ctrl.projectId
          ctrl.survey = survey
          resolve()
        } else if (
          !ctrl.surveyId &&
          (ctrl.surveyTemplateId || ctrl.cloneSurveyId)
        ) {
          var action = ctrl.surveyTemplateId
            ? surveyTemplateService.getById
            : getSurvey
          var id = ctrl.surveyTemplateId || ctrl.cloneSurveyId
          action(id)
            .then(function(surveyOrTemplate) {
              ctrl.survey = surveyOrTemplate
              var origin = originService.createForDuplicate(ctrl.survey)
              ctrl.survey.toNewSurvey()
              ctrl.survey.origins.unshift(origin)
              ctrl.originalTitle = ctrl.survey.title
              if (ctrl.title) {
                ctrl.survey.title = ctrl.title
              }
              ctrl.survey.projectId = ctrl.projectId
              resolve()
            })
            .catch(function() {
              reject()
            })
        } else {
          // actual surveys require you to be logged in
          if (!userService.isLoggedIn()) {
            reject()
            userService.saveRequestedUrl()
            $state.go('login')
            return
          }
          getSurvey(ctrl.surveyId)
            .then(function(survey) {
              ctrl.survey = survey
              resolve()
            })
            .catch(function() {
              reject()
            })
        }
      })
    }

    function onRefresh() {
      var promise = getSurvey(ctrl.survey.id).then(function(survey) {
        ctrl.survey = survey
      })
      ctrl.survey.queue.add(promise, 'Refreshing Survey')
    }

    function getSurvey(id) {
      return surveyService.get(id).then(function(data) {
        return new Survey().deserialize(data)
      })
    }

    function incrementActiveChannelCount(event, channel) {
      if (ctrl.survey.id !== channel.survey.id) {
        return
      }
      ctrl.survey.activeChannelCount += 1
    }

    function decrementActiveChannelCount(event, channel) {
      if (ctrl.survey.id !== channel.survey.id) {
        return
      }
      ctrl.survey.activeChannelCount -= 1
    }

    function isValidTab(value) {
      return _.some(ctrl.tabs, { value: value })
    }

    function getSurveyTitle() {
      if (ctrl.survey.isNew()) {
        return 'Untitled Survey'
      } else {
        return ctrl.survey.reference || ctrl.survey.title
      }
    }

    function setTab(value) {
      if (!surveyExplorerExitService.check()) return
      ctrl.tab = value
      glUtils.changeParams({ tab: value }, true)
      setAppTitle()
    }

    function setAppTitle() {
      var surveyTitle = getSurveyTitle()
      var tabLabel = _.find(ctrl.tabs, { value: ctrl.tab }).label
      app.setTitle(surveyTitle + ' | ' + tabLabel)
    }

    function promptDiscard() {
      if (ctrl.dirty) {
        return glDialog
          .confirm(
            'Discard Changes?',
            'Your survey has un-saved changes that will be lost. Do you want to continue?'
          )
          .then(function() {
            loadSurvey() // reload survey to discard changes
            return true
          })
      }
      return $q.resolve(false)
    }

    function back() {
      if (!surveyExplorerExitService.check()) return
      $window.history.back()
    }

    function canEdit() {
      return ctrl.survey
    }

    function canManageChannels() {
      return ctrl.survey && !ctrl.survey.isNew()
    }

    function canAnalyze() {
      return ctrl.survey && !ctrl.survey.isNew()
    }

    function onSurveyCreate(surveyId) {
      var cloneSurveyId = ctrl.cloneSurveyId
      // set surveyId and remove redundant URL params
      glUtils.changeParams(
        {
          surveyId: surveyId,
          surveyTemplateId: null,
          cloneSurveyId: null,
          projectId: null,
        },
        true
      )
      if (!ctrl.surveyId) {
        ctrl.surveyId = surveyId
        loadChannels()
        if (cloneSurveyId) {
          copyTranslationsFromAnotherSurvey(cloneSurveyId)
        } else {
          loadTranslations()
        }
      }
    }

    function toggleLegacy(value) {
      value = _.isBoolean(value) ? value : !ctrl.legacy
      ctrl.legacy = value
      legacyPrefs.set('enabled', value)
    }
  }
})()
