;(function() {
  'use strict'

  Service.$inject = ["$q", "$rootScope", "api", "glAnalytics", "glAuthService", "glDialog", "deviceService", "assetService", "userService", "subscriberService", "publicationsResource", "publicationMetricsResource", "userPublicationsResource", "reportLinkService", "Publication"];
  angular.module('app.core').service('publicationService', Service)

  /* @ngInject */
  function Service(
    $q,
    $rootScope,
    api,
    glAnalytics,
    glAuthService,
    glDialog,
    deviceService,
    assetService,
    userService,
    subscriberService,
    publicationsResource,
    publicationMetricsResource,
    userPublicationsResource,
    reportLinkService,
    Publication
  ) {
    var self = this

    var cache = {}
    var views = {}

    self.Events = {
      RATED: 'RATED',
    }
    self.Types = Publication.Types
    self.TypeInfo = Publication.getTypeInfo()
    self.States = Publication.States
    self.Metrics = {
      VIEW: 'VIEW',
      ACQUIRE: 'ACQUIRE',
      RATE: 'RATE',
    }

    self.getBlank = getBlank
    self.getByView = getByView
    self.getById = getById
    self.getByProject = getByProject
    self.save = save
    self.populateResources = populateResources
    self.updateState = updateState
    self.updateInStore = updateInStore
    self.requestToPublish = requestToPublish
    self.acquire = acquire
    self.unAssign = unAssign
    self.rate = rate
    self.trackMetric = trackMetric

    init()

    function init() {
      $rootScope.$on(glAuthService.Events.USER_CHANGE, onUserChange)
    }

    function getBlank() {
      return new Publication()
    }

    function getByView(name, filter, paginationObj) {
      var view = views[name + filter]
      if (!view) {
        view = views[name + filter] = {
          loading: false,
          error: null,
          docs: null,
        }
      }
      view.loading = true
      view.error = false

      paginationObj = _.isPlainObject(paginationObj) ? paginationObj : {}
      var data = _.assign(paginationObj, { view: name, filter: filter })
      setGroupId(data)
      publicationsResource
        .get(data)
        .success(function(x, resp) {
          view.docs = _.map(resp, function(data) {
            return constructAndCache(data)
          })
          view.loading = false
        })
        .error(function() {
          view.loading = false
          view.error = true
        })
      return view
    }

    function constructAndCache(data) {
      var cached = cache[data.id]
      if (cached) {
        cached.deserialize(data)
        return cached
      }
      cache[data.id] = new Publication().deserialize(data)
      return cache[data.id]
    }

    function getById(id, callback) {
      var cached = cache[id]
      if (cached) {
        callback(null, cached)
      }

      var data = { id: id }
      setGroupId(data)
      publicationsResource
        .get(data)
        .success(function(x, resp) {
          callback(null, constructAndCache(resp))
        })
        .error(function() {
          callback(true)
        })
    }

    function getByProject(projectId) {
      return api.projectReports.list({ id: projectId }).then(function(resp) {
        return _.map(resp, function(data) {
          return constructAndCache(data)
        })
      })
    }

    function save(publication) {
      return $q(function(resolve, reject) {
        publication.isSaving = true
        makeSurveyPreviewTokens(publication)
          .then(function() {
            var action = publication.id ? 'update' : 'create'
            publicationsResource[action](publication.serialize())
              .success(function(x, resp) {
                if (resp.id) {
                  publication.createdAt = moment()
                  publication.id = resp.id
                }
                publication.updatedAt = moment()
                publication.isSaving = false
                resolve()
              })
              .error(function(data, status) {
                publication.isSaving = false
                reject({ data: data, status: status })
              })
          })
          .catch(function() {
            publication.isSaving = false
            reject()
          })
      })
    }

    function makeSurveyPreviewTokens(publication) {
      var promises = []
      _.each(publication.resources, function(resource) {
        if (resource.surveyPreviewToken === true) {
          promises.push(
            $q(function(resolve, reject) {
              // TODO: refactor to use ReportShare factories everywhere
              // this is way more complicated than it needs to be

              reportLinkService
                .getBySurvey(resource.surveyId)
                .then(function(links) {
                  // use existing if any
                  var link = _.find(links, function(link) {
                    return link.forResource
                  })
                  if (link) {
                    resource.surveyPreviewToken = link.token
                    resolve()
                    return
                  }

                  // otherwise create it
                  link = reportLinkService.spawn()
                  link.surveyId = resource.surveyId
                  link.isRestricted = true
                  link.isFilters = true
                  link.forResource = true
                  reportLinkService
                    .create(link)
                    .then(function() {
                      resource.surveyPreviewToken = link.token
                      resolve()
                    })
                    .catch(reject)
                })
                .catch(reject)
            })
          )
        }
      })
      return $q.all(promises)
    }

    function populateResources(publication) {
      var promises = []
      _.each(publication.resources, function(resource) {
        if (resource.assetId) {
          promises.push(
            $q(function(resolve, reject) {
              assetService
                .get(resource.assetId)
                .then(function(asset) {
                  resource.asset = asset
                  resolve()
                })
                .catch(reject)
            })
          )
        }
        if (resource.previewAssetId) {
          promises.push(
            $q(function(resolve, reject) {
              assetService
                .get(resource.previewAssetId)
                .then(function(asset) {
                  resource.previewAsset = asset
                  resolve()
                })
                .catch(reject)
            })
          )
        }
      })
      return $q.all(promises)
    }

    function updateState(publication, state) {
      return $q(function(resolve, reject) {
        publication.isUpdatingState = true
        var action = {
          AVAILABLE: 'available',
          // NOT_AVAILABLE: 'notAvailable',
          // COMING_SOON: 'comingSoon'
        }[state]
        if (!action) return reject()
        publicationsResource[action]({ id: publication.id })
          .success(function() {
            publication.state = state
            publication.updatedAt = moment()
            publication.isUpdatingState = false
            resolve()
          })
          .error(function() {
            publication.isUpdatingState = false
            reject()
          })
      })
    }

    function requestToPublish(publication) {
      return $q(function(resolve, reject) {
        publication.isRequesting = true
        publicationsResource
          .requestToPublish({ id: publication.id })
          .success(function() {
            publication.isRequesting = false
            resolve()
          })
          .error(function(err) {
            publication.isRequesting = false
            reject(err)
          })
      })
    }

    function updateInStore(publication, inStore) {
      return $q(function(resolve, reject) {
        var oldValue = publication.inStore
        publication.inStore = inStore
        publication.isUpdatingStore = true
        publicationsResource
          .update(publication.serialize())
          .success(function() {
            publication.updatedAt = moment()
            publication.isUpdatingStore = false
            resolve()
          })
          .error(function() {
            publication.inStore = oldValue
            publication.isUpdatingStore = false
            reject()
          })
      })
    }

    function acquire(publication, token) {
      return $q(function(resolve, reject) {
        publication.loading = 'acquire'
        var action = token ? 'acquireWithToken' : 'acquire'
        var data = { id: publication.id, addPackToken: token }
        setGroupId(data)

        publicationsResource[action](data)
          .success(function() {
            publication.canAccess = true
            publication.inLibrary = true
            publication.acquiredAt = moment()
            publication.loading = false
            subscriberService.removePremiumToken(token)
            var action = token
              ? 'acquire-premium-via-token'
              : 'acquire-' + _.lowerCase(publication.type.type)
            glAnalytics.track('publication', action, publication.id)
            trackMetric(publication, self.Metrics.ACQUIRE)
            resolve()
          })
          .error(function() {
            publication.loading = false
            reject()
          })
      })
    }

    function unAssign(publication, subscriberId) {
      return $q(function(resolve, reject) {
        publication.loading = 'unassign'
        var data = { publicationId: publication.id, ownerId: subscriberId }

        userPublicationsResource
          .unAssign(data)
          .success(function() {
            // optimistic property updates
            publication.canAccess = false
            publication.inLibrary = false
            publication.acquiredAt = null
            publication.loading = false
            glAnalytics.track('publication', 'unassign', publication.id)
            resolve()
          })
          .error(function() {
            publication.loading = false
            reject()
          })
      })
    }

    function onRatingSubmit(publication) {
      $rootScope.$emit(self.Events.RATED)
      return subscriberService.refresh().then(function() {
        // Fetch the publication again to recalculate rating
        return getById(publication.id, angular.noop)
      })
    }

    function rate(publication, stars) {
      var template = [
        '<gl-dialog class="publication-rating-form__dialog">',
        '<g-dialog-header ',
        'title="Rate Publication" ',
        'on-close="dialog.cancel()"',
        '></g-dialog-header>',
        '<publication-rating-form ',
        'publication="publication" ',
        'stars="stars" ',
        'on-submit="onSubmit(); dialog.close()" ',
        '/>',
        '</gl-dialog>',
      ]
      return glDialog.show({
        template: template.join(''),
        locals: {
          publication: publication,
          stars: stars,
          onSubmit: function() {
            return onRatingSubmit(publication)
          },
        },
      })
    }

    function trackMetric(publication, action, fields) {
      fields = fields || {}
      var user = userService.getUser()
      var subscriber = subscriberService.getSubscriber()
      var data = {
        deviceId: deviceService.getId(),
        userId: user && user.id,
        subscriberId: subscriber && subscriber.id,
        publicationId: publication.id,
        action: action,
        data: fields.data,
        stars: fields.stars,
      }
      return $q(function(resolve, reject) {
        publicationMetricsResource
          .create(data)
          .success(function() {
            if (action === self.Metrics.RATE) {
              publication.hasRated = true
            }
            resolve()
          })
          .error(reject)
      })
    }

    function onUserChange() {
      views = {}
    }

    function setGroupId(data) {
      // SSR users require groupId to be passed on some of the publication endpoints
      if (userService.isSSR()) {
        data.groupId = subscriberService.getSubscriber().id
      }
    }
  }
})()
