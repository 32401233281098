;(function() {
  'use strict'

  Controller.$inject = ["colors"];
  angular.module('app.core').component('gRibbon', {
    controller: Controller,
    templateUrl: 'g-ribbon.html',
    bindings: {
      label: '@',
      color: '@',
      align: '@',
      fontSize: '@',
    },
  })

  /* @ngInject */
  function Controller(colors) {
    var ctrl = this

    ctrl.$onInit = onInit

    function onInit() {
      ctrl.align = ctrl.align || 'left'
      ctrl.style = {
        backgroundColor: colors.parse(ctrl.color),
      }
      if (ctrl.fontSize) {
        ctrl.style.fontSize = ctrl.fontSize + 'px'
      }
    }
  }
})()
