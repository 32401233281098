;(function() {
  'use strict'

  Factory.$inject = ["configService", "cloudinaryService", "Publisher", "Resource", "Price"];
  angular.module('app.core').factory('Publication', Factory)

  /* @ngInject */
  function Factory(
    configService,
    cloudinaryService,
    Publisher,
    Resource,
    Price
  ) {
    var twoWeeksAgo = moment().subtract(14, 'days')

    var Types = {
      FREE: 'FREE',
      PAID: 'PAID',
      PREMIUM: 'PREMIUM',
    }

    var TypeInfo = [
      {
        type: Types.FREE,
        label: 'Free',
        description: 'A report anyone can get.',
      },
      {
        type: Types.PAID,
        label: 'Paid',
        description: 'A priced report for people to purchase.',
      },
      {
        type: Types.PREMIUM,
        label: 'Premium',
        description: ' A report available only to premium members on Glow.',
      },
    ]

    var States = {
      NOT_AVAILABLE: 'NOT_AVAILABLE',
      AVAILABLE: 'AVAILABLE',
      COMING_SOON: 'COMING_SOON',
    }

    var Defaults = {
      id: null,
      name: null,
      description: null,
      researchNotes: null,
      imageUrl: null,
      canAccess: false,
      inLibrary: false,
      publisher: null,
      publisherId: null,
      type: null,
      inStore: false,
      price: new Price(),
      resources: [],
      metrics: {
        totalViews: 0,
        uniqueViews: 0,
        acquisitions: 0,
      },
      state: States.AVAILABLE,
      projectId: null,
    }

    var MinimumRatingsForDisplay = 5

    function Publication() {
      _.defaultsDeep(this, Defaults)
    }

    Publication.prototype.Types = Publication.Types = Types
    Publication.prototype.States = Publication.States = States
    Publication.prototype.getTypeInfo = Publication.getTypeInfo = getTypeInfo

    Publication.prototype.deserialize = function(data) {
      var self = this
      var metrics = _.isPlainObject(data.metrics) ? data.metrics : {}
      var ratings = _.isPlainObject(metrics.ratings) ? metrics.ratings : {}

      this.id = data.id
      this.name = data.name
      this.description = data.description
      this.researchNotes = data.reportResearchNotes
      this.imageUrl = data.imageUrl
      this.canAccess = !!data.access
      this.firstAddedToStore =
        data.firstAddedToStore && moment(data.firstAddedToStore)
      this.isNewToStore =
        this.firstAddedToStore && this.firstAddedToStore.isAfter(twoWeeksAgo)
      this.acquiredAt =
        data.access && data.access.acquiredAt && moment(data.access.acquiredAt)
      this.inLibrary = data.access && data.access.inLibrary
      this.hasRated = data.access && data.access.hasRated
      this.publisher =
        data.publisher && new Publisher().deserialize(data.publisher)
      this.publisherId = data.publisher && data.publisher.id
      this.ownerId = data.ownerId
      this.type = this.getTypeInfo(data.acquisitionType)
      this.inStore = data.inStore
      this.price = new Price().deserialize(data.prices)
      this.resources = _.map(data.resources, function(resource) {
        return new Resource().deserialize(resource)
      })
      this.metrics = {}
      this.metrics.totalViews = metrics.totalViews
      this.metrics.uniqueViews = metrics.uniqueViews
      this.metrics.acquisitions = metrics.acquisitions

      this.industry = data.industry
      this.country = data.country
      this.projectId = data.projectId

      // ratings
      this.metrics.ratings = []
      _.times(5, function(idx) {
        var star = idx + 1
        self.metrics.ratings[idx] = ratings[star] || 0
      })
      this.metrics.totalRatings = _.sum(this.metrics.ratings)
      if (this.metrics.totalRatings > 0) {
        var weightedTotal = _(this.metrics.ratings)
          .map(function(value, idx) {
            return value * (idx + 1)
          })
          .sum()
        var averageRating = weightedTotal / this.metrics.totalRatings
        this.metrics.averageRating = averageRating.toFixed(1)
      } else {
        this.metrics.averageRating = 0
      }

      this.state = data.state
      this.createdAt = data.createdAt && moment(data.createdAt)
      this.updatedAt = data.updatedAt && moment(data.updatedAt)
      _.defaultsDeep(this, Defaults)
      return this
    }

    Publication.prototype.serialize = function() {
      var data = {}
      data.id = this.id
      data.name = this.name
      data.description = this.description
      data.reportResearchNotes = this.researchNotes
      data.imageUrl = this.imageUrl
      if (this.canAccess) {
        data.access = {}
        data.access.inLibrary = this.inLibrary
        data.access.hasRated = this.hasRated
        data.access.acquiredAt =
          this.acquiredAt && this.acquiredAt.toISOString()
      }
      data.firstAddedToStore =
        this.firstAddedToStore && this.firstAddedToStore.toISOString()
      data.publisher = this.publisher && this.publisher.serialize()
      data.publisherId = this.publisherId
      data.ownerId = this.ownerId
      data.acquisitionType = this.type.type
      data.inStore = this.inStore
      data.prices = this.price.serialize()
      data.resources = _.map(this.resources, function(resource) {
        return resource.serialize()
      })
      data.metrics = {}
      data.metrics.totalViews = this.metrics.totalViews
      data.metrics.uniqueViews = this.metrics.uniqueViews
      data.metrics.acquisitions = this.metrics.acquisitions
      data.metrics.ratings = this.metrics.ratings
      data.industry = this.industry
      data.country = this.country
      data.projectId = this.projectId
      data.state = this.state
      data.createdAt = this.createdAt && this.createdAt.toISOString()
      data.updatedAt = this.updatedAt && this.updatedAt.toISOString()
      return data
    }

    Publication.prototype.isNew = function() {
      return !this.id
    }

    Publication.prototype.getIncludedResourceCategories = function() {
      return _(this.resources)
        .map(function(resource) {
          return resource.category
        })
        .uniqBy(function(category) {
          return category.type
        })
        .sortBy(function(category) {
          return category.type
        })
        .value()
    }

    Publication.prototype.hasResourceCategories = function(categories) {
      return (
        _.intersectionBy(
          this.getIncludedResourceCategories(),
          categories,
          function(category) {
            return category.type
          }
        ).length > 0
      )
    }

    Publication.prototype.canDisplayAverageRating = function() {
      return this.metrics.totalRatings >= MinimumRatingsForDisplay
    }

    Publication.prototype.canBeRated = function() {
      return this.inLibrary && !this.hasRated
    }

    Publication.prototype.validate = function() {
      var errors = []
      if (!this.name) {
        errors.push('Name is required.')
      }
      if (!this.imageUrl) {
        errors.push('Image is required.')
      }
      if (!this.description) {
        errors.push('Description is required.')
      }
      if (!this.resources.length) {
        errors.push('At least one resource must be added.')
      }
      if (this.type.type === Types.PAID) {
        var hasZeroAmount = _.some(this.price.list, function(money) {
          return !money.amount
        })
        if (hasZeroAmount) {
          errors.push('You must set a price for a paid report.')
        }
      }
      this.errors = errors
      return errors.length === 0
    }

    Publication.prototype.getImageUrl = function(size) {
      return cloudinaryService.transform(this.imageUrl, size || 350, 'jpg')
    }

    Publication.prototype.getLink = function() {
      return configService.getSubscriberPortalUrl('/publication/', this.id)
    }

    Publication.prototype.getTags = function() {
      return {
        title: this.name,
        description: this.description,
        image: this.imageUrl,
        url: this.getLink(),
      }
    }

    Publication.prototype.isFiltered = function(filter) {
      var hasTypesFilter = filter && filter.types && filter.types.length
      var hasCategoriesFilter =
        filter && filter.categories && filter.categories.length
      var hasIndustriesFilter =
        filter && filter.industries && filter.industries.length
      var hasCountriesFilter =
        filter && filter.countries && filter.countries.length
      var hasProjectsFilter =
        filter && filter.projects && filter.projects.length
      var hasQueryFilter = filter && filter.query

      var types = hasTypesFilter
        ? _.some(filter.types, { type: this.type.type })
        : true
      var categories = hasCategoriesFilter
        ? this.hasResourceCategories(filter.categories)
        : true
      var industries = hasIndustriesFilter
        ? this.industry // not all publications have industry
          ? _.some(filter.industries, { code: this.industry.code })
          : false
        : true
      var countries = hasCountriesFilter
        ? this.country // not all publications have country
          ? _.some(filter.countries, { cc: this.country.cc })
          : false
        : true
      var projects = hasProjectsFilter
        ? this.projectId // not all publications belong to a project
          ? _.some(filter.projects, { id: this.projectId })
          : false
        : true
      var query = hasQueryFilter
        ? _.includes(this.name.toLowerCase(), filter.query.toLowerCase())
        : true

      return types && categories && industries && countries && projects && query
    }

    function getTypeInfo(typeOrTypes) {
      var type = _.isString(typeOrTypes) ? typeOrTypes : null
      var types = _.isArray(typeOrTypes) ? typeOrTypes : null

      // if type specified - return just that info
      if (type) {
        return _.cloneDeep(_.find(TypeInfo, { type: type }))
      }

      // if types array, return just those types
      if (types) {
        var info = _.filter(TypeInfo, function(info) {
          return _.includes(types, info.type)
        })
        return _.cloneDeep(info)
      }

      // otherwise return all info
      return _.cloneDeep(TypeInfo)
    }

    return Publication
  }
})()
