;(function() {
  'use strict'

  Controller.$inject = ["$window", "$scope", "$element", "userService", "publisherService"];
  angular.module('app.core').component('publicationGrid', {
    controller: Controller,
    templateUrl: 'publication-grid.html',
    bindings: {
      publications: '<',
      orderKey: '@',
      filter: '<',
      canRate: '<',
      newRibbon: '<',
      publishedRibbon: '<',
      limit: '<',
    },
  })

  /* @ngInject */
  function Controller(
    $window,
    $scope,
    $element,
    userService,
    publisherService
  ) {
    var ctrl = this

    // min must be ordered high -> low
    ctrl.breakpoints = [
      { min: 900, perRow: 4 },
      { min: 680, perRow: 3 },
      { min: 390, perRow: 2 },
      { min: 0, perRow: 1 },
    ]

    ctrl.$onInit = onInit
    ctrl.orderBy = orderBy
    ctrl.isPrivate = isPrivate

    function onInit() {
      ctrl.isSSR = userService.isSSR()
      ctrl.elem = angular.element($element).children()[0]
      angular.element($window).on('resize', layout)
      layout()
    }

    function layout() {
      $scope.$applyAsync(function() {
        _.each(ctrl.breakpoints, function(breakpoint) {
          if (breakpoint.min <= ctrl.elem.offsetWidth) {
            ctrl.width = 100 / breakpoint.perRow + '%'
            return false
          }
        })
      })
    }

    function orderBy(publication) {
      return publication[ctrl.orderKey || 'updatedAt'].unix()
    }

    function isPrivate(publication) {
      var isPublisher = publisherService.isPublisher(publication.publisherId)
      return !ctrl.isSSR && !isPublisher && !publication.inStore
    }
  }
})()
